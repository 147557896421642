import React, { Component } from "react"
import Header from "./header"
import Helmet from "react-helmet"
import Footer from "./footer"

import "./layout.css"
class layout extends Component {
  state = {
    smallScreen: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }
  render() {
    const { children, page } = this.props
    return (
      <div className="main">
        <Helmet title="Lisas Urter">
          <meta name="description" content="Lisas urter importerer urter fra Phyto Products Limited i England, et firma som garanterer rene urter. Lisas urter bruker kun urter som er godkjent i Norge." />
        </Helmet>
        <Header page={page} smallScreen={this.state.smallScreen} />
        <div className="content">{children}</div>
        <Footer />
      </div>
    )
  }
}

export default layout
