import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../images/lisasurter-logo.jpg"
import menuIcon from "../../images/menu.svg"

class Header extends Component {
  openSidebar() {
    document.getElementById("sidebar").style.display = "block"
  }

  closeSidebar() {
    document.getElementById("sidebar").style.display = "none"
  }
  render() {
    let page = this.props.page
    return this.props.smallScreen ? (
      <div className="sidebar">
        <button onClick={this.openSidebar} className="sidebar-button-off">
          <img src={menuIcon} alt="Menu" className="menu-icon"></img>
        </button>
        <div
          className="sidebar-inner"
          style={{ display: "none" }}
          id="sidebar"
          onClick={this.closeSidebar}
        >
          <button className="sidebar-button-on">
            <img src={menuIcon} alt="Menu" className="menu-icon"></img>
          </button>
          <Link to="/" className="sidebar-link">
            <button className="sidebar-item">HJEM</button>
          </Link>
          <Link to="/products" className="sidebar-link">
            <button className="sidebar-item">PRODUKTER</button>
          </Link>
          <Link to="/about" className="sidebar-link">
            <button className="sidebar-item">OM OSS</button>
          </Link>
          <Link to="/contact" className="sidebar-link">
            <button className="sidebar-item">KONTAKT</button>
          </Link>
        </div>
      </div>
    ) : (
      <header className="header">
        <Link to="/">
          <button className={page === "home" ? "button-active" : "button"}>
            HJEM
          </button>
        </Link>
        <Link to="/products">
          <button className={page === "products" ? "button-active" : "button"}>
            PRODUKTER
          </button>
        </Link>
        <Link to="/">
          <img src={logo} alt="Lisas Urter logo" className="logo"></img>
        </Link>
        <Link to="/about">
          <button className={page === "about" ? "button-active" : "button"}>
            OM OSS
          </button>
        </Link>
        <Link to="/contact">
          <button className={page === "contact" ? "button-active" : "button"}>
            KONTAKT
          </button>
        </Link>
      </header>
    )
  }
}
export default Header
